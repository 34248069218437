import React from "react"
import {
  Header,
  BlogCardBig,
  Footer,
  NewsletterBig,
  Subtitle,
  Intro,
  Pager,
} from "../components/index"
import "../styles/app.css"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Helmet from "react-helmet"
import Favicon from "../img/favicon.ico"

const Blog = ({ data, pageContext }) => {
  const articles = data.posts.nodes

  const pageNumber = pageContext.pageNumber
  console.log(`Pagenumber: ${pageNumber}`)
  const metaDescription = `Gamblers Bay Blog - your online destination in 2022 for gambling news and articles. Made by professionals for the professionals - Page ${
    pageNumber + 1
  }.`
  const metaTitle = `Gamblers Bay Blog - Top Online Gambling Articles - Page ${
    pageNumber + 1
  }`
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="shortcut icon" type="image/x-icon" href={Favicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Helmet>

      <Header />
      <Intro />

      <div className="blog-posts-wrapper">
        <Subtitle>All blog posts</Subtitle>
        {articles.map(article => (
          <BlogCardBig
            slug={article.slug}
            title={article.title}
            description={documentToReactComponents(article.description.json)}
            date={article.published}
            alt={article.title}
            img={article.banner && article.banner.fluid ? article.banner : null}
          />
        ))}

        <Pager pageContext={pageContext} />
      </div>

      <NewsletterBig />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    posts: allContentfulPost(skip: $skip, limit: $limit) {
      nodes {
        id
        slug
        published(formatString: "DD/MM/YYYY - h:m A")
        title
        description {
          json
        }
        content {
          json
        }
        category {
          name
        }
        metaTitle
        metaDescription
        banner {
          fluid {
            base64
            sizes
            src
            srcSet
          }
          title
        }
      }
    }
  }
`

export default Blog
